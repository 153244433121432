<template>
  <v-container
    style="position: relative; width: 100%; justify-content: center; height: 100%; background: #FFFFFF 0% 0% no-repeat padding-box; opacity: 1;">


    <div class="text-center">


      <v-row justify="center">
        <v-dialog v-model="dialog2" width="600px">
          <v-card>
            <v-card-title style="background-color:#EE811E ; color: white;">
              <span :class="termsConditionsTitle">TÉRMINOS Y CONDICIONES</span>
            </v-card-title>
            <v-card-text style="text-align: justify;">
              <br>
              <h2>TÉRMINOS Y CONDICIONES DEL SOAP ELECTRÓNICO SOUTHBRIDGE COMPAÑÍA DE SEGUROS GENERALES S.A.</h2>
              <br>
              La compañía que cubre el riesgo es Southbridge Compañía de Seguros Generales S.A. El 
              detalle de las condiciones y exclusiones están contenidas en la póliza incorporada al
              depósito de pólizas de la CMF bajo el código POL 3 2013 0487 y en las condiciones 
              particulares del contrato. La contratación de este seguro cumple con la Circular 1.864 del 
              21.01.08 de la CMF., que regula la venta del SOAP por Internet. Los datos o antecedentes 
              ingresados son de exclusiva responsabilidad del contratante asumiendo las consecuencias 
              derivadas de errores o inexactitudes de información.
              <br><br><br>
              <h2>Sobre el Uso y Veracidad de la Información.</h2>
              <br>
              Los datos o antecedentes ingresados tanto del vehículo como del propietario son de 
              exclusiva responsabilidad del solicitante y debe concordar con los que se registran en el 
              Registro Nacional de Vehículos Motorizados, los cuales puede obtener del padrón o 
              certificado de dominio del vehículo. Cualquier error o inexactitudes de información será 
              exclusiva responsabilidad del solicitante, liberando a la compañía de toda responsabilidad.
              <br><br>
              El Contratante autoriza conscientemente a que Southbridge Compañía de Seguros Generales 
              S.A. -o alguna de sus empresas relacionadas- lo contacte a través de los medios señalados 
              por él mismo al momento de entregar sus datos, o por cualquier otro, con el objetivo de darle a 
              conocer nuevos productos y servicios, o bien evaluar la calidad del servicio y/o de los 
              productos contratados.
              <br><br><br>
              <h2>Cláusula de uso de datos.</h2>
              <br>
              Southbridge Compañía de Seguros Generales S.A  en su preocupación por el cumplimiento 
              de los principios de Conducta de Mercado, transparencia y protección de datos; solicitamos 
              su <b>autorización,</b> marcando la casilla de aceptación en el proceso de contratación, para 
              almacenar y tratar los datos personales que suministre voluntariamente, a través de 
              formularios, correo electrónico u otros medios electrónicos, tanto en la cotización del seguro, 
              en la formalización del contrato de seguros, como durante la vigencia del mismo, los que se 
              obtengan mediante grabación de conversaciones telefónicas y los generados por la 
              navegación en los sitios web de la compañía y sus empresas relacionadas, para la 
              tramitación, seguimiento y actualización de cualquier solicitud de información, la gestión de la 
              actividad aseguradora y el cumplimiento del propio contrato de seguro y el envío de 
              información y publicidad. Así mismo <b>acepta</b> que sus datos pueden ser entregados, 
              exclusivamente para las finalidades indicadas anteriormente, a entidades de prestación de 
              servicios de comunicación, cobranza y asistencia.
              <br><br><br>
              <h2>Devolución o Anulaciones</h2>
              <br>
              El seguro SOAP ELECTRÓNICO no permite devoluciones ni anulaciones.
              <br><br><br>
              <h2>Condiciones de Pago</h2>
              <br>
              Southbridge Compañía de Seguros Generales S.A. pone a disposición de sus cliente para el 
              pago del Seguro Obligatorio de Accidentes Personales (SOAP), la plataforma de pago online 
              WebPay, con las siguientes condiciones generales: 
              <!-- generar lista  -->
              <ul>
                <li>Para todos los efectos de esta contratación, solo se entenderá́ pagada la prima del 
                    seguro una vez que el sistema de pago apruebe la respectiva transacción y que 
                    Southbridge haya recibido conforme el monto correspondiente al total de la 
                    transacción por la compra de él o los SOAP que realice el cliente.
                </li>
                <li>Si se produjera una duplicidad de pago por parte del cliente, es decir, dicho cliente 
                    pagara dos veces la prima del seguro, se podrá́ devolver uno de estos abonos previa 
                    solicitud por parte del cliente a la compañía, siempre que los dineros ya se encuentren 
                    disponibles en Southbridge Compañía de Seguros Generales S.A. y que el cliente 
                    proporciones la información bancaria necesaria para realizar el trámite de devolución 
                    de prima duplicada.
                </li>
                <li>
                  El plazo para las devoluciones de prima duplicada será de máximo 30 días corridos.
                </li>
                <li>
                  Si existiesen dudas sobre el proceso de reembolso de prima duplicada, podrá 
                  contactarse con nuestro call center 800 200 802 (02 2826 8000, desde móviles). El 
                  horario de atención es: de lunes a viernes: de 09:00 hrs. a 18:00 hrs.
                </li>
              </ul>
              <br><br><br>
              <h2>Información de los Vehículos</h2>
              <br>
              Según la normativa vigente, los datos del vehículo no pueden ser modificados a través de 
              este medio, en caso de que estos no correspondan o tenga dudas respecto de la exactitud 
              de ellos comuníquese a nuestro centro de soporte Web al 800 200 802 (02 2826 8000, 
              desde móviles), o acérquese a nuestra casa matriz ubicada en Presidente Riesco 5335 of
              15, Las Condes, Santiago.
              <br><br><br>
              <h2>Despacho a Domicilio</h2>
              <br>
              Por normativa de la CMF, circular Nº 1864, si el contratante así lo desea, y así lo expresa al 
              momento de la contratación, Southbridge Compañía de Seguros Generales S.A. deberá 
              enviar el certificado SOAP a su domicilio, el que deberá ser despachado por un servicio de 
              correo fehaciente y expedito dentro de los 3 días hábiles siguientes a cursada la solicitud. El 
              despacho a domicilio tiene un recargo de $5.000 pesos en la Región Metropolitana y de 
              $8.000 en otras regiones. El certificado que recibirá por correo postal es una impresión del 
              mismo SOAP electrónico, tal como la que el cliente puede imprimir con una impresora 
              particular común. Se enviarán todos los SOAP a un mismo domicilio.
              <br><br><br>
              <h2>Consultas</h2>
              <br>
                Si tiene dudas o necesita apoyo en su proceso de contratación del seguro llámenos a 
                nuestro centro de soporte Web al 800 200 802 (02 2826 8000 , desde móviles). El horario de 
                atención es: de lunes a viernes: de 09:00 hrs. a 18:00 hrs.
            </v-card-text>
            <v-card-actions>
              <v-btn :class="btnClose" elevation="0" text @click="dialog2 = false">Cerrar</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-row>

      <v-row justify="center"><v-dialog v-model="dialog" max-width="800px" min-height="800px">
          <v-card>
            <v-card-text>
              <div v-if="!loadLogin" class="form-container">
                <v-col>
                  <br>
                  <br>
                  <h1 class="titulo text-center"> Inicia tu sesión</h1><br>
                  <h2 class="text-center"> Ingresa tu rut y contraseña de Puntos Cencosud</h2><br>
                  <br><label>Rut</label>
                  <v-form ref="form" v-model="valid">
                    <v-text-field v-model="newRut2" :rules="rutRules2" label="Ingresa tu rut" required filled solo
                      hide-details="auto" @input="changeRut2">
                    </v-text-field><br><label>Contraseña</label>
                    <v-text-field v-model="contrasenaCliente" :rules="contrasenaRules" label="Ingresa tu contraseña"
                      required filled solo hide-details="auto" type="password">
                    </v-text-field>
                  </v-form>
                  <br><v-btn class="login" @click="logincenco()"> Iniciar sesión </v-btn><br>
                  <br><a
                    style="color: rgb(108, 117, 125); text-decoration: none; background-color: transparent;  justify-content: center;"
                    href="https://www.puntoscencosud.cl/puntos/recuperar">Quiero recuperar mi contraseña de Puntos
                    Cencosud</a>
                </v-col>
                <v-col v-if="screen > 800">
                  <v-img contain :src="cencoLogin" />
                </v-col>
              </div>
              <div v-if="loadLogin" class="text-center">
                <br>
                <br>
                <v-progress-circular :size="100" color="primary" indeterminate></v-progress-circular><br><br>
                Cargando
              </div>
            </v-card-text>
          </v-card>
        </v-dialog>
      </v-row>

      <br>
      <div class="rectangulo">
        <div class="valor">
          <b> Valor total a pagar</b>
        </div>
        <br>

        <div :class="priceBox">
          <hr class="hrStyle">
          <b class="precio">{{ formatPrice(priceTotal) }}</b>
          <hr class="hrStyle">
        </div>
        <br>

      </div>

      <!-- <div v-if="(typeCliente == 'clientes') && (patentes.length == 1)">

        <br>

        <div style="display: flex;">
          <hr style="width: 50px; height: 0px; border: 1px solid #D0D0D0; opacity: 0; margin:auto;"><b
            class="precio">o</b>
          <hr style="width: 50px; height: 0px; border: 1px solid #D0D0D0; opacity: 0; margin:auto;">
        </div>

        <br>

        <div style="display: flex;">
          <hr style="width: 50px; height: 0px; border: 1px solid #D0D0D0; opacity: 1; margin:auto;"><b
            class="precio">{{ patentes[0].pricePuntos }}
            Puntos Cencosud</b>
          <hr style="width: 50px; height: 0px; border: 1px solid #D0D0D0; opacity: 1; margin:auto;">
        </div>

      </div> -->

      <br>
      <br>

      <template>
        <v-data-table :footer-props="{ 'items-per-page-text': '' }" :headers="headers" :items="patentes"
          :items-per-page="isMobile ? 1 : 5" class="elevation-1 table" :class="{ 'table-stripped-mobile': isMobile }" no-data-text="NO HAY REGISTROS ¡AÑADE TU SOAP!">
          <template v-slot:top>
            <!-- INICIO DIALOG WEB -->
            <v-dialog v-if="screen > 800" v-model="dialogDelete" max-width="600px">
              <v-card>

                <v-card-title class="text-h5">
                  <div class="titleBox">
                    <b class="title1">¿Estás seguro de </b>
                    <b class="title2">eliminar</b>
                    <b class="title1">este producto?</b>
                  </div>
                </v-card-title>

                <v-card-actions>

                  <v-spacer></v-spacer>
                  <v-btn class="btnClosePopUp" text @click="closeDelete">Cancelar</v-btn>
                  <v-btn class="btnConfirmPopUp" id="quitarOtro_3" text @click="deleteItemConfirm">Confirmar</v-btn>
                  <div class="space"></div>
                  <v-spacer></v-spacer>
                </v-card-actions>
              </v-card>
            </v-dialog>
            <!-- FIN DIALOG WEB -->


            <!-- INICIO DIALOG MOVIL -->
            <v-dialog v-else v-model="dialogDelete" max-width="360px">
              <v-card>

                <v-card-title class="text-h5">
                  <div class="titleBoxMobile">
                    <b class="title1">¿Estás seguro de <br><span style="color:#EE811E">eliminar</span> este producto?</b>
                  </div>
                </v-card-title>

                <v-card-actions>
                  <v-spacer></v-spacer>
                  <div class="btnBoxMobile">
                    <v-btn class="btnClosePopUp2" text @click="closeDelete">Cancelar</v-btn>
                    <br><br>
                    <v-btn class="btnConfirmPopUp2" id="quitarOtro_3" text @click="deleteItemConfirm">Confirmar</v-btn>
                    <div class="space"></div>
                  </div>
                  <v-spacer></v-spacer>
                </v-card-actions>
              </v-card>
            </v-dialog>
            <!-- FIN DIALOG MOVIL -->

          </template>


          <template v-slot:item.actions="{ item }">
            <v-icon class="mr-2 trash" @click="deleteItem(item)">
              mdi-delete
            </v-icon>
          </template>

          <template v-slot:item.price="{ item }">
            <label style="margin:auto">{{ formatPrice(item.price) }}</label>
          </template>



        </v-data-table>

      </template>

      <br><br><br>

      <div v-if="pagar != true" class="finalBox">
        <b class="question">¿QUIERES AGREGAR OTRO VEHÍCULO?</b>
        <br v-if="screen <= 800">
        <br v-if="screen <= 800">
        <v-btn class="button1" id="agregarOtro_3" @click="NewCarro()">Agregar Otro </v-btn>
        <br v-if="screen <= 800">
        <br v-if="screen <= 800">
        <v-btn v-if="screen > 800" class="button2" @click="obtenerToken()" id="confirmar_3">Continuar Pago</v-btn>

        <v-btn v-if="screen <= 800" class="button2Mobile" style="margin-top: 5%" @click="obtenerToken()"
          id="confirmar_3">Continuar Pago</v-btn>
      </div>

      <v-form ref="form" v-model="valid">

        <!-- <v-col v-if="pagar != false" style="max-width: 320px; margin:auto;">
          <b class="title"> INGRESE RUT PAGADOR</b>
          <br>
          <br>
          <label class="labelName"><b>Ingrese el RUT</b></label>
          <v-text-field v-model="newRut" :rules="rutRules" label="Ingrese RUT de quien paga" required solo
            :class="patentFieldView" hide-details="auto" @change="changeRut">
          </v-text-field>
        </v-col> -->

        <br>

        <v-col v-if="pagar != false">
          <div :class="authorizationBox">
            <label class="authorization">
              El Contratante autoriza a que Southbridge Compañía de Seguros Generales S.A. -o alguna de sus empresas
              relacionadas- lo
              contacte a través de los medios señalados por él mismo al momento de entregar sus datos, o por cualquier
              otro,
              con el objetivo de darle a conocer nuevos productos y servicios, o bien evaluar la calidad del servicio y/o
              de los
              productos contratados.
            </label>
          </div>
          <br><br>
          <div class="checkbox">

            <v-checkbox style="margin-top:2.5%" v-model="conditions" :rules="conditionsRules" required dense
              @change="sendToken()"></v-checkbox>

            <!-- <label style="margin-top:2%;background-color: yellowgreen;"> -->
            <b style="margin:auto">Declaro conocer y aceptar los términos y condiciones de SOAP Electrónico <br v-if="screen<=800"><a @click="dialog2 = true">(Ver condiciones aquí).</a></b>
            <!-- </label> -->

          </div>
          <br v-if="screen >= 800">
        </v-col>
      </v-form>

      <!-- <br>
      <br>
      <br> -->

      <!-- <div style="background-color: #EFEFEF;">

        <v-col v-if="(pagar != false) && !loadCanje">

          <br>
          <br>

          <b v-if="status == false" class="valor"> Puedes pagar tu SOAP con: </b>

          <v-radio-group v-if="status == false" autofocus v-model="switchFormaPago" :rules="confirmRules" required row>
            <v-radio style="margin: auto;" value='true' off-icon on-icon><template v-slot:label>
                <div class="forecast-selector"> -->
      <!-- <v-img v-if="type != 'soap-clientes-becs'" contain :src="webPayLogo"
                  style="width: 300px; height: 135px; margin: auto;" />
                <br>
                <v-radio-group v-if="type != 'soap-clientes-becs'" v-model="webPaySelect">
                  <v-radio v-model="pagoSelect" style="margin: auto;"></v-radio>
                </v-radio-group>
                <v-img v-if="type == 'soap-clientes-becs'" contain :src="cencoWebPayLogo"
                  style="width: 300px; height: 135px; margin: auto;" />
                <br>
                <v-radio-group v-if="type == 'soap-clientes-becs'" v-model="webPaySelect">
                  <v-radio v-model="pagoSelect" style="margin: auto;"></v-radio>
                </v-radio-group> -->
      <!-- <v-img contain :src="webPayLogo" style="width: 250px; height: 135px; margin-left: -5%;" />
                  <br>
                </div>
              </template>
            </v-radio> -->
      <!-- <v-radio v-if="(typeCliente == 'clientes') && patentes.length == 1" style="margin: auto;"
          @click="obtenerToken()" value='false' off-icon on-icon><template v-slot:label>
            <div class="forecast-selector">
              <v-img contain :src="cencoLogo" style="width: 300px; height: 135px; margin: auto;" />
              <br>
              <v-radio-group v-model="CencoSelect">
                <v-radio style="margin: auto;"></v-radio>
              </v-radio-group>
            </div>
          </template></v-radio> -->
      <!-- </v-radio-group>

          <v-btn class="button1">Volver </v-btn>

          <br><br>

          <form v-if="token" :action="webPayUrl" method="POST" ref="form_payment">
            <input type="hidden" name="token_ws" :value="token" />
            <v-btn class="button2" key="first" type="submit" value="Pagar" @click="submitPago">
              Ir a pagar
            </v-btn>
          </form> -->

      <!-- <br v-if="status == false">
          <br v-if="status == false">

          <div v-if="status == true">

            <v-btn class="butoon" style="width: 230px; height: 50px; background: #0069B6 0% 0% no-repeat padding-box; box-shadow: 0px 0px 10px #767676; border: 1px solid #DEDEDE;
            border-radius: 30px; opacity: 1; text-align: center; font: normal normal normal 13px Arial; letter-spacing: 0.66px; color: white; text-transform: 
            uppercase; opacity: 1;" @click="OtroPago()">
              Elige otro medio de pago
            </v-btn>

            <br>
            <br>
            <br>

            <b class="condicion"> Para continuar tu compra y pagar con tus puntos Cencosud, porfavor ingresa el código de
              seguridad que ha llegado a tu celular
            </b>

          </div> -->

      <!-- <v-col v-if="tokensocio && (loadPuntos == false) && status == true">

            <v-data-table v-if="!loadCanje" :headers="headersCenco" :items="clienteCenco" hide-default-footer
              class="elevation-1">
              <template v-slot:item.opt="{ item }">
                <br>
                <v-text-field style="max-width: 100px; margin: auto;" v-if="item.pointsCenco <= item.points"
                  v-model="codigoOpt" required solo :class="labelsSup">
                </v-text-field>
                <v-text-field v-if="item.pointsCenco > item.points" disabled filled solo :class="labelsSup"
                  hide-details="auto">
                </v-text-field>
              </template>
              <template v-slot:item.actions="{ item }">
                <v-btn v-if="item.pointsCenco <= item.points" :disabled="!codigoOpt"
                  style="background-color: #0069B6; color: white; margin-top: -10%;" @click="canjearpuntos()">
                  Canjear
                </v-btn>
                <v-btn v-if="item.pointsCenco > item.points" disabled
                  style="background-color: #0069B6; color: white; margin-top: -10%;" @click="canjearpuntos()">
                  Canjear
                </v-btn>
              </template>
            </v-data-table>

          </v-col> -->

      <!-- </v-col>

      </div> -->

      <div v-if="loadCanje" class="text-center">
        <br>
        <br>
        <v-progress-circular :size="100" color="primary" indeterminate></v-progress-circular><br><br>
        Cargando
      </div>

    </div>
    <!-- <div v-if="pagar != false" class="pay">
      <br>
      <div class="payTitleImage">
        <b class="payTitle"> PUEDES PAGAR TU SOAP CON </b>
        <v-img contain :src="webPayLogo2" max-width="220" class="payImage" />
      </div>
      <br>
      <div class="payButtons">
        <v-btn class="btnBack" @click="validate()">Volver</v-btn>
        <v-btn class="btnPay" @click="validate()">Ir a pagar</v-btn>
      </div>
      <br>
    </div> -->
  </v-container>
</template>

<script>
import RutUtils from "../utils/rut.utils";
import webPayLogo from "@/assets/images/webpay.png";
import webPayLogo2 from "@/assets/images/WebpayPlus.png";
import cencoWebPayLogo from "@/assets/images/transbank-cenco.png";
import cencoLogo from "@/assets/images/logocenco.png";
import ShieldService from "../services/shield.service";
import BodumService from '../services/bodum.service';
import cencoLogin from "@/assets/images/CencoImage.jpg";
import swal from 'sweetalert';

export default {

  name: "PlanForm",
  props: [],
  data: () => ({
    typePlan: null,
    isMobile: false,
    status: false,
    screen: window.screen.width,
    typeCliente: null,
    keygen: null,
    patentes: [],
    priceTotal: 0,
    priceBox: null,
    propietarioData: {
      rutPropietario: null,
      propietario: null,
    },
    patentFieldView: null,
    authorizationBox: null,
    termsConditionsTitle: null,
    btnClose: null,
    externalId: null,
    planId: null,
    webPaySelect: true,
    CencoSelect: true,
    pagoSelect: false,
    type: null,
    token: null,
    pagar: false,
    conditions: null,
    loadPuntos: true,
    loadLogin: false,
    loadCanje: false,
    dialog: false,
    dialog2: false,
    dialogOpt: false,
    newRut2: "",
    validarcanje: null,
    valid: false,
    rutCliente: null,
    contrasenaCliente: null,
    cencoLogin,
    codigoOpt: null,
    respuestaLogin: null,
    puntosclientes: null,
    tokenpartner: null,
    inputOptEsVisible: false,
    switchFormaPago: null,
    tokensocio: null,
    generaropt: null,
    formatter: new Intl.NumberFormat("es-CL", {
      style: "currency",
      currency: "CLP",
    }),
    webPayLogo,
    webPayLogo2,
    cencoWebPayLogo,
    cencoLogo,
    webPayUrl: process.env.VUE_APP_WEBPAY_URL,
    newRut: "",
    dialogDelete: false,
    dialog: false,
    editedIndex: -1,
    currentPage: 1,
    itemsPerPage: 5,
    editedItem: {
      patenteNumber: '',
      typeVehicle: '',
      modelVehicle: '',
      personEmail: '',
      price: 0,
    },
    defaultItem: {
      patenteNumber: '',
      typeVehicle: '',
      modelVehicle: '',
      personEmail: '',
      price: 0,
    },
    headers: [
      {
        text: 'Patente',
        align: 'start',
        sortable: false,
        value: 'patenteNumber',
        align: 'center',
      },
      { text: 'Tipo de Vehículo', value: 'typeVehicle', align: 'center', sortable: false, },
      { text: 'Modelo', value: 'modelVehicle', align: 'center', sortable: false, },
      { text: 'Correo', value: 'personEmail', align: 'center', sortable: false, },
      { text: 'Valor SOAP', value: 'price', align: 'center', sortable: false, },
      { text: 'Eliminar', value: 'actions', sortable: false, align: 'center', },
    ],
    headersCenco: [
      {
        text: 'Nombre Cliente',
        align: 'start',
        sortable: false,
        value: 'name',
        align: 'center',
      },
      { text: 'Apellido Cliente', value: 'surname', align: 'center', },
      { text: 'Puntos Cliente', value: 'points', align: 'center', },
      { text: 'Puntos Plan a Contratar', value: 'pointsCenco', align: 'center', },
      { text: 'Código de verificación', value: 'opt', sortable: false, align: 'center', },
      { text: 'Realizar canje', value: 'actions', sortable: false, align: 'center', },
    ],
    clienteCenco: [],
    confirmRules: [(v) => !!v || "Selecciona una opción"],
    rutRules: [
      (v) => !!v || "Rut es requerido",
      (v) => RutUtils.validateRut(v) || "Rut no válido",
    ],
    rutRules2: [
      (v) => !!v || "Rut del cliente es requerido",
      (v) => RutUtils.validateRut(v) || "Rut no válido",
    ],
    contrasenaRules: [
      (v) => !!v || "La contraseña es requerida",
    ],
    conditionsRules: [
      (v) => !!v || "",
    ]
  }),
  async mounted() {
    this.checkMobile();
    window.addEventListener('resize', this.checkMobile);
    this.propietarioData.propietario = 'true'
    this.pagar = false
    this.typePlan = localStorage.getItem('typePlan');
    const query = this.$route.query;

    if (query.external) {
      this.externalId = query.external
    }

    if (this.screen > 800) {
      this.patentFieldView = 'patentFieldView'
      this.priceBox = 'priceBox'
      this.authorizationBox = 'authorizationBox'
      this.termsConditionsTitle = 'termsConditionsTitle'
      this.btnClose = 'btnClose'

    } else if (this.screen <= 800) {
      this.patentFieldView = 'patentFieldView2'
      this.priceBox = 'priceBox2'
      this.authorizationBox = 'authorizationBox2'
      this.termsConditionsTitle = 'termsConditionsTitle2'
      this.btnClose = 'btnClose2'

    }


    if (this.typePlan && query.keygen) {
      this.keygen = query.keygen
      switch (this.typePlan) {
        case 'soap-clientes-becs':
          this.typeCliente = 'clientes'
          break;
        case 'soap-clientes-becs':
          this.typeCliente = 'general'
          break;
        // case 'soap-colaborador-becs':
        //   this.typeCliente = 'colaborador'
        //   break;
        case 'soap-5990-becs':
          this.typeCliente = '5990'
          break;
        case 'soap-5690-becs':
          this.typeCliente = '5690'
          break;
        case 'soap-5490-becs':
          this.typeCliente = '5490'
          break;
        case 'soap-5190-becs':
          this.typeCliente = '5190'
          break;
        case 'soap-4990-becs':
          this.typeCliente = '4990'
          break;

        default:
          break;
      }
    }

    var excluyentes = [];
    this.patenteData = await ShieldService.getCars(this.keygen).then((response) => {
      if (response.status == 'ok') {
        response.data.forEach(car => {
          excluyentes.push(car.patenteNumber);
          this.patentes.push(car)
          this.priceTotal = this.priceTotal + car.price
        });

        // Verificar si hay cadenas de texto repetidas
        const hayRepetidos = excluyentes.length !== new Set(excluyentes).size;

        if (hayRepetidos) {
          swal({
            title: "Existen patentes repetidas!",
            text: "Será redirigido al inicio de la plataforma",
            icon: "error",
            button: "Ok",
          }).then(() => {
            window.location.href = process.env.VUE_APP_DOMINIO + this.typeCliente + '/inicio'
          });
        }

      } else if (response.status == 'failed') {
        swal({
          title: "No posee SOAP's en su carrito!",
          text: "Será redirigido al inicio de la plataforma",
          icon: "info",
          button: "Ok",
        }).then(() => {
          window.location.href = process.env.VUE_APP_DOMINIO + this.typeCliente + '/inicio'
        });
      }
    })

  },

  methods: {
    deleteItem(item) {
      this.editedIndex = this.patentes.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.dialogDelete = true
    },
    async deleteItemConfirm() {
      await ShieldService.deleteCar(this.patentes[this.editedIndex]._id).then((response) => {
        if (response == 'Delete car') {
          this.patentes.splice(this.editedIndex, 1)
          this.closeDelete()
        }
      })
      // console.log("edited2: "+this.editedIndex)
      if( this.editedIndex < 1){
        window.location.href = process.env.VUE_APP_DOMINIO + this.typeCliente + '/inicio';
      } else {
        location.reload();
      }
    },
    closeDelete() {
      this.dialogDelete = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },
    changeRut2(rut) {
      this.rutCliente = RutUtils.cleanRut(rut);
      this.newRut2 = RutUtils.formatRut(rut);
    },
    back() {
      this.$emit("back");
    },
    validate(plan) {
      this.$emit("next-step", 2, plan);
    },
    NewCarro() {
      const agregarOtro_3 = document.getElementById('agregarOtro_3');
      this.token = null,
      window.location.href = process.env.VUE_APP_DOMINIO + this.typeCliente + '/inicio?newCar=true';
    },
    sendToken() {
      var params = {
        token: this.token,
        conditions: this.conditions,
        rut: this.propietarioData.rutPropietario
      };
      this.$emit("getToken", params);
    },
    async obtenerToken() {
      // const confirmar_3 = document.getElementById('confirmar_3');
      // if (this.switchFormaPago == "false") {
      //   this.status = false
      //   this.token = null
      //   this.dialog = true;
      //   this.webPaySelect = true
      // }
      // if (this.switchFormaPago == "true") {
      this.planId = localStorage.getItem('planId')
      var webPayToken = await ShieldService.create(this.keygen, this.planId);
      if (webPayToken.status == 'ok') {
        this.token = webPayToken.data.token;
        this.pagar = true;
        this.sendToken();
      } else {
        swal({
          title: "UPS! Algo salió mal.",
          text: "Vuelva a intentarlo nuevamente.",
          icon: "info",
          button: "Ok",
        }).then(() => {
          window.location.href = process.env.VUE_APP_DOMINIO + this.typeCliente + '/inicio'
        });
      }
      this.CencoSelect = true
      this.webPaySelect = false
      // }
    },
    async logincenco() {
      this.CencoSelect = false
      this.webPaySelect = true
      if (this.$refs.form.validate()) {
        this.loadLogin = true
        this.tokenpartner = await BodumService.getTokenPartner();
        console.log("token partner: ", this.tokenpartner)
        this.dialog = true;
        this.respuestaLogin = await BodumService.loginsocio(this.tokenpartner, this.rutCliente, this.contrasenaCliente);
        console.log("token socio: ", this.respuestaLogin)
        console.log("usuario: ", this.rutCliente)
        console.log("contraseña: ", this.contrasenaCliente)
        if (this.respuestaLogin.code == 'USER_TMP_PWD' || this.respuestaLogin.code == 'OK') {
          this.loadPuntos = true
          this.tokensocio = this.respuestaLogin.token;
          this.puntosclientes = await BodumService.consultaPuntos(this.tokenpartner, this.rutCliente);
          this.loadPuntos = false;
          if (this.switchFormaPago == 'false' && this.tokensocio != null) {
            var datos = {
              name: this.puntosclientes.payload.nombre,
              surname: this.puntosclientes.payload.apellidoPaterno,
              points: this.puntosclientes.payload.puntos,
              pointsCenco: this.patentes[0].pricePuntos,
            }
            this.clienteCenco.push(datos)
            this.generaropt = await BodumService.GenerarOPT(this.tokenpartner, this.tokensocio);
            console.log(this.generaropt)
            if (this.generaropt.result.message == "OK") {
              this.dialogOpt = true
            }
          }
          this.loadLogin = false
          swal({
            title: "Login Exitoso!",
            text: "Ahora puede continuar con la compra!",
            icon: "success",
            button: "Ok",
          });
          this.status = true
          this.dialog = false;

        } else {
          this.loadLogin = false
          swal({
            title: "Credenciales inválidas",
            text: "",
            icon: "error",
            button: "Ok",
          });
        }
      }

    },
    async canjearpuntos() {
      this.loadCanje = true
      console.log(this.externalId)
      this.validarcanje = await BodumService.PagoPuntos(this.tokenpartner, this.tokensocio, this.codigoOpt, this.patentes[0].pricePuntos, this.externalId, this.keygen);
      console.log(this.validarcanje);

      if (this.$refs.form.validate()) {
        if (this.validarcanje.message == 'OK') {
          const query = this.$route.query;
          var res = await ShieldService.pagoCencosud(this.keygen, this.typePlan)
          console.log(res)
          this.loadCanje = false
          swal({
            title: "Canje Exitoso!",
            text: "La compra del seguro se ha completado",
            icon: "success",
            button: "Ok",
          });
          window.location.href = res;
        } else {
          this.loadCanje = false
          swal({
            title: "Canje inválido",
            text: "",
            icon: "error",
            button: "Ok",
          });
        }
      }
    },
    async getToken() {
      this.planId = localStorage.getItem('planId')
      var webPayToken = await ShieldService.create(this.keygen, this.planId);
      if (webPayToken.status == 'ok') {
        this.token = webPayToken.data.token;
      } else {
        swal({
          title: "UPS! Algo salió mal.",
          text: "Vuelva a intentarlo nuevamente.",
          icon: "info",
          button: "Ok",
        }).then(() => {
          window.location.href = process.env.VUE_APP_DOMINIO + this.typeCliente
        });
      }
    },
    checkMobile() {
      this.isMobile = window.innerWidth <= 800;
    },
    formatPrice(number) {
      return this.formatter.format(number);
    },
    async changeRut(rut) {
      this.propietarioData.rutPropietario = RutUtils.formatRut(rut);
      this.newRut = RutUtils.formatRut(rut);

      await ShieldService.updatePagador(this.keygen, this.propietarioData);
      this.sendToken();
    },
    async submitPago(event) {
      event.preventDefault();

      if (this.$refs.form.validate()) {
        //  WebPay
        if (this.switchFormaPago == 'true') {
          this.$refs.form_payment.submit();
          return;
        }
      }
    },
    OtroPago() {
      this.status = false
      this.clienteCenco = []
    }
  },
};
</script>


<style lang="css" scoped>
.titulo {
  color: rgb(126, 43, 137);
  font-size: 1.5rem;
  font-weight: bold;
  margin: 0px;
  font-family: Nunito;
  text-align: center;
  line-height: 1.2;
}

.subtitulo {
  font-family: Nunito;
  width: 280px;
  text-align: center;
  margin: 15px auto auto;
  line-height: 1.2;
}

.form-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.imageCenco {
  background-image: url("~@/assets/images/CencoImage.png");
  background-position: center;
  background-size: cover;
  display: flex;
}

.login {
  display: inline-block;
  box-sizing: border-box;
  border-radius: 0.3rem;
  background-color: rgb(19, 65, 105);
  height: 40px;
  line-height: 40px;
  width: 100%;
  padding: 0px 1.5rem;
  cursor: pointer;
  color: white;
  font-weight: 600;
  margin: auto;
}

#app>div.v-dialog__content.v-dialog__content--active>div>div>div>div>div:nth-child(1)>button {
  height: 36px;
  min-width: 64px;
  padding: 0 16px;
  background-color: rgb(19, 65, 105);
}

.precio {
  height: 40px;
  text-align: center;
  font: Arial;
  font-size: 30px;
  letter-spacing: 0.53px;
  color: #2D387C;
  opacity: 1;
}

.patenteNumber {
  width: 138px;
  height: 40px;
  text-align: center;
  font: Arial;
  font-size: 35px;
  letter-spacing: 0.53px;
  color: #4E4E4E;
  text-transform: uppercase;
  opacity: 1;
}

.valor {
  background-color: #EE811E;
  color: #FFF;
  font-family: 'Helvetica';
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: -0.24px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  padding-top: 2%;
  padding-bottom: 2%;
}

.condicion {
  width: 56px;
  height: 21px;
  text-align: center;
  font: Arial;
  font-size: 14px;
  letter-spacing: 0.26px;
  color: #0069B6;
  text-transform: uppercase;
  opacity: 1;
}

.patente {
  width: 76px;
  height: 21px;
  font: Arial;
  font-size: 17px;
  letter-spacing: 0.26px;
  color: #4E4E4E;
  text-transform: uppercase;
  opacity: 1;
}

.webpay {
  width: 80%;
  height: 206px;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 6px #00000029;
  border: 1px solid #D5D5D5;
  border-radius: 10px;
  opacity: 1;
  margin: auto;
}

.cenco {
  width: 80%;
  height: 206px;
  background: #753783 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 6px #00000029;
  border: 1px solid #D5D5D5;
  border-radius: 10px;
  opacity: 1;
  margin: auto;
}

.rectangulo {
  box-shadow: 0px 0px 6px #00000029;
  max-width: 420px;
  opacity: 1;
  margin: auto;
  margin-bottom: 2%;
  border-radius: 10px;

}

.hrStyle {
  width: 147px;
  height: 0px;
  border: 1px solid #D0D0D0;
  opacity: 1;
  margin: auto;
}

.question {
  color: #2D387C;
  font-family: 'Inter', sans-serif;
  margin-right: 1%;
  font-size: 20px;
}

.button1 {
  width: 270px;
  height: 50px !important;
  background-color: #2D387C !important;
  border-radius: 10px !important;
  opacity: 1;
  text-align: center;
  font: normal normal normal 20px Arial;
  letter-spacing: 0.66px;
  color: white;
  opacity: 1;
  margin-left: 1%;
  text-transform: inherit;
  font-weight: bold;
  margin-right: 2%;
}

.button2 {
  width: 270px;
  height: 50px !important;
  background-color: #EE811E !important;
  border-radius: 10px !important;
  opacity: 1;
  text-align: center;
  font: normal normal normal 20px Arial;
  letter-spacing: 0.66px;
  color: white;
  opacity: 1;
  margin-left: 1%;
  text-transform: inherit;
  font-weight: bold;
}
.button2Mobile {
  width: 270px;
  height: 50px !important;
  background-color: #EE811E !important;
  border-radius: 10px !important;
  opacity: 1;
  text-align: center;
  font: normal normal normal 20px Arial;
  letter-spacing: 0.66px;
  color: white;
  opacity: 1;
  margin-left: 1%;
  text-transform: inherit;
  font-weight: bold;
  margin-bottom: -6%;
}

.finalBox {
  margin-left: -4%;
}

.title {
  color: #2D387C;
  font-weight: bold;
  font-family: 'Inter', sans-serif;
  letter-spacing: -0.36px !important;
}

.labelName {
  text-align: left;
  font: Arial;
  font-size: 12px;
  letter-spacing: 0px;
  color: #333;
  opacity: 1;
  font-weight: bold;
  margin-left: -72%;
  font-family: 'Inter', sans-serif;
  letter-spacing: -0.2px;
  line-height: 38.5px;
}

.checkbox {
  max-width: 590px;
  margin: auto;
  color: #333;
  font-family: 'Inter', sans-serif;
  letter-spacing: -0.2px;
  display: flex;
  text-align: justify;
  font-size: 13px;
  margin-bottom: -5%;
  margin-top: -2%;
}

.payTitleImage {
  color: #2D387C;
  display: flex;
  justify-content: center;
}

.payButtons {
  display: flex;
  justify-content: center;
}

.pay {
  background-color: #EFEFEF;
  /* margin-left: -20%; */
  /* max-width: x100%; */
  width: 100vw;
  position: absolute;
  left: 50%;
  margin-left: -200px;
}

.payTitle {
  font-size: 24px;
  margin-top: 0.5%;
}

.payImage {
  margin-left: 2%;
}

.btnBack {
  width: 270px;
  height: 50px !important;
  background-color: #2D387C !important;
  border-radius: 8px !important;
  opacity: 1;
  text-align: center;
  font: normal normal normal 20px Arial;
  letter-spacing: 0.66px;
  color: white !important;
  opacity: 1;

  font-weight: bold;
}

.btnPay {
  width: 260px;
  height: 50px !important;
  background-color: #4F74E3 !important;
  border-radius: 8px !important;
  opacity: 1;
  text-align: center;
  font: normal normal normal 20px Arial;
  letter-spacing: 0.66px;
  color: white !important;
  opacity: 1;
  margin-left: 6%;
  font-weight: bold;
}

.tableHeaders {
  background-color: #EE811E;
  color: white !important;
  border: 2px solid #2D387C;
}

.text-center {
  text-align: center;
}

.table {
  border: 2px solid #2D387C;
  margin-top: -2%;
  margin-bottom: -1%;
}

.btnClosePopUp {
  background-color: #2D387C;
  color: #FFF;
  border-radius: 8px;
  font-weight: bold;
  letter-spacing: 0.8px;
  width: 30%;
  margin-top: -2%;
}

.btnClosePopUp2 {
  height: 40px !important;
  background-color: #2D387C;
  color: #FFF;
  border-radius: 8px;
  font-weight: bold;
  letter-spacing: 0.8px;
  width: 100%;
  margin-top: -2%;
}

.btnConfirmPopUp {
  background-color: #EE811E;
  color: #FFF;
  border-radius: 8px;
  font-weight: bold;
  letter-spacing: 0.8px;
  width: 30%;
  margin-top: -2%;
}

.btnConfirmPopUp2 {
  height: 40px !important;
  background-color: #EE811E;
  color: #FFF;
  border-radius: 8px;
  font-weight: bold;
  letter-spacing: 0.8px;
  width: 100%;
  margin-top: -2%;
}

.title1 {
  color: #2D387C;
  font-size: 22px;
}

.title2 {
  color: #EE811E;
  margin-right: 1%;
  font-size: 22px;
}

.titleBox {
  width: 100%;
  text-align: center;
}

.titleBoxMobile {
  width: 90%;
  margin: auto;
  text-align: center;
  margin-top: 1%;
  margin-bottom: 3%;
}

.btnBoxMobile {
  display: block;
  width: 70%;
}


.patentFieldView {
  width: 400px;
  height: 50px !important;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  border: 1px solid #B2B2B2;
  border-radius: 10px;
  opacity: 1;
  margin-left: auto;
  text-transform: uppercase;

}

.patentFieldView2 {
  width: 100%;
  height: 50px;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  border: 1px solid #B2B2B2;
  border-radius: 10px;
  opacity: 1;
  /* margin-left: 41%; */
  text-transform: uppercase;
}

.priceBox {
  display: flex;
  margin-top: -2%;
}

.priceBox2 {
  display: flex;
  margin-top: -2%;
  width: 90%;
  margin-left: 4%;
}
</style>

<style lang="css">
.v-data-table>.v-data-table__wrapper>table>thead>tr>th {
  background-color: #EE811E;
  color: white !important;
}

.space {
  margin-bottom: 10% !important;
}

.trash {
  color: #333333 !important;
}

.authorization {
  font-family: 'Inter', sans-serif;
  color: #333;
  letter-spacing: -0.2px;
  font-size: 13px;
}

.authorizationBox {
  width: 70%;
  text-align: justify;
  margin: auto;
  margin-top: -3%;
}

.authorizationBox2 {
  width: 100%;
  text-align: justify;
  margin-top: -3%;
}
.termsConditionsTitle {
  font-size: 24px;
}

.termsConditionsTitle2 {
  font-size: 20px;
}
.btnClose {
  background-color: #EE811E;
  color: white !important;
  margin-left: 84%;
  border-radius: 8px;
  width: 80px;
  margin-bottom: 1%;
}

.btnClose2 {
  background-color: #EE811E;
  color: white !important;
  margin-left: 74%;
  border-radius: 8px;
  width: 80px;
  margin-bottom: 1%;

}
/* .table-stripped-mobile tbody tr:nth-child(odd){
  background-color: yellowgreen; Color de fondo para filas impares 
} */
</style>