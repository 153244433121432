import axios from 'axios'

const { VUE_APP_BODUM_URL, VUE_APP_SHIELD_URL } = process.env

export default class BodumService {

  static async getTokenPartner() {
    try {
      const TokenPartner = await axios.post(
        `${VUE_APP_BODUM_URL}/cencosud/loginpartner`
      );
      return TokenPartner.data;

    } catch (error) {
      return {
        status: "error",
        message: "Ocurrio un error al obtener token partner",
      };
    }
  }

  static async loginsocio(token, username, password) {
    try {

      const loginsocio = await axios.post(
        `${VUE_APP_BODUM_URL}/cencosud/loginsocio`,
        {
          username: username,
          password: password,
          token: token,
        },
      );

      return loginsocio.data;


    } catch (error) {
      return {
        status: "error",
        message: "Ocurrio un error al obtener token socio",
      };
    }
  }

  static async GenerarOPT(tokenpartner, tokensocio) {
    try {
      const GenerarOPT = await axios.post(
        `${VUE_APP_BODUM_URL}/cencosud/generaropt`,
        {
          tokenpartner: tokenpartner,
          tokensocio: tokensocio,
        },
      );
      return GenerarOPT.data;


    } catch (error) {
      return {
        status: "error",
        message: "Ocurrio un error al obtener codigo sms",
      };
    }
  }

  static async PagoPuntos(tokenpartner, tokensocio, codigoOtp, points, externalProductId, keygen) {
    try {
      console.log("puntos cenco: ", points)
      console.log("externalID: ", externalProductId)

      var externalID = `${Math.round(10000 + Math.random() * 90000)}-01-18-002`

      const PagoPuntos = await axios.post(
        `${VUE_APP_BODUM_URL}/cencosud/canje`,
        {
          tokenpartner: tokenpartner,
          tokensocio: tokensocio,
          externalId: externalID,
          points: points,
          codigoOtp: codigoOtp,
          externalProductId: externalProductId,
        },
      );

      if (PagoPuntos.data.message == 'OK') {
        const shieldRes = await axios.post(
          `${VUE_APP_SHIELD_URL}/car/setExternalId`,
          {
            externalID: externalID,
            keygen: keygen
          }
        );

        console.log("respuesta exteral id: ", shieldRes.data)
      }
      console.log("respuesta canje de puntos: ", PagoPuntos.data);
      return PagoPuntos.data;

    } catch (error) {
      return {
        status: "error",
        message: "Ocurrio un error al procesar el pago",
      };
    }
  }

  static async consultaPuntos(tokenpartner, rutCliente) {
    try {
      const consultaPuntos = await axios.post(
        `${VUE_APP_BODUM_URL}/cencosud/consultasocio`,
        {
          rut: rutCliente,
          token: tokenpartner,
        },
      );
      return consultaPuntos.data;

    } catch (error) {
      return {
        status: "error",
        message: "rut invalido",
      };
    }
  }

}